<template>
    <div class="userManage">
        <div class="orderManage-top">
            <el-row :gutter="10">
                <el-col :span="4">

                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_1.png" width="40" class="vm" />
                            <span class="vm">新客户</span>
                        </div>
                        <el-popover placement="bottom" :visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" class="aa"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px; font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div >
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerNewNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayNewCount }}
                            </div>
                        </el-popover>

                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;{{
                                agentDifflist.diffNewCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_2.png" width="40" class="vm" />
                            <span class="vm">审核驳回</span>
                        </div>
                        <el-popover placement="bottom":visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" style="background-color: #fff;"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div>
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerBackNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayBackCount }}
                            </div>
                        </el-popover>
                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;{{
                                agentDifflist.diffBackCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_5.png" width="40" class="vm" />
                            <span class="vm">审核通过</span>
                        </div>
                        <el-popover placement="bottom" :visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" style="background-color: #000;"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div>
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerAccessNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayAccessCount }}
                            </div>
                        </el-popover>
                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;{{
                                agentDifflist.diffAccessCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_4.png" width="40" class="vm" />
                            <span class="vm">报装完成</span>
                        </div>
                        <el-popover placement="bottom" :visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" style="background-color: #000;"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div>
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerBZNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayBzCount }}
                            </div>
                        </el-popover>
                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;{{
                                agentDifflist.diffBzCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_3.png" width="40" class="vm" />
                            <span class="vm">并网预警</span>
                        </div>
                        <el-popover placement="bottom" :visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" style="background-color: #000;"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div>
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerBWNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayBwCount }}
                            </div>
                        </el-popover>
                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;{{
                                agentDifflist.diffBwCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="order-pic shadow">
                        <div class="pic-top">
                            <img src="@/assets/images/index_3.png" width="40" class="vm" />
                            <span class="vm">客户总数</span>
                        </div>
                        <el-popover placement="bottom" :visible-arrow="false" popper-class="down-popover" width="100" trigger="hover" style="background-color: #fff;"
                            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                            <div>
                                <div style="display: flex;">
                                    <div style="background-color: red; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">日:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.todayCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #9FE080; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">周:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.weekCount }}</div>
                                </div>
                                <div style="display: flex;">
                                    <div style="background-color: #73C0DE; width: 12px; height: 12px; border-radius: 20px; margin-top: 5px;"></div>
                                    <div style="margin-left: 20px;">月:</div>
                                    <div style="margin-left: 30px;font-weight: bold; color: #fff;">{{
                                        CustomerNewNum.monthCount }}</div>
                                </div>
                            </div>
                            <div slot="reference" @mouseover="showPopover('/houseCustomer/queryCustomerTotalNum')"
                                class="fw600 mb10">
                                {{ agentDifflist.todayTotalCount }}
                            </div>
                        </el-popover>
                        <div>
                            较昨日<i class="color">&nbsp;&nbsp;
                                {{
                                    agentDifflist.diffTotalCount
                                }}</i>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="manage">
            <el-row :gutter="7">
                <el-col :span="6">
                    <el-card shadow="hover" class="cardUser">
                        <div class="cardUserItem">
                            <div class="imgAll">
                                <img style="width: 30px;" src="@/assets/images/agent/u1.png" />
                            </div>
                            <div style="flex: 1">
                                <div style="font-size: 16px; font-weight: bold;">代理商汇总</div>
                                <div class="cardUserItemshu">
                                    <div style="margin-left: 20px;">
                                        <div class="commonUser">总数</div>
                                        <div class="commonUser">本月新增</div>
                                        <div class="commonUser">设备保证金</div>
                                    </div>
                                    <div style="margin-left: 26px">
                                        <div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.totalCountAgent }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.monthCountAgent
                                                }}
                                            </div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.equipmentDeposit }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card shadow="hover" class="cardUser">
                        <div class="cardUserItem">
                            <div class="imgAll">
                                <img style="width: 30px;" src="@/assets/images/agent/u2.png" />
                            </div>
                            <div>
                                <div style="font-size: 16px; font-weight: bold;">客户汇总</div>
                                <div class="cardUserItemshu">
                                    <div style="margin-left: 26px;">
                                        <div class="commonUser">客户总数</div>
                                        <div class="commonUser">报装完成</div>
                                        <div class="commonUser">并网预警</div>
                                    </div>
                                    <div style="margin-left: 20px">
                                        <div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.totalCountCustomer }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.reportCount }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.bWCount }}</div>
                                        </div>
                                    </div>
                                    <div style="margin-left: 16px;">
                                        <div class="commonUser">新增客户</div>
                                        <div class="commonUser">审核通过</div>
                                        <div class="commonUser">审核驳回</div>
                                    </div>
                                    <div style="margin-left: 20px">
                                        <div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.customerNewCount }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.customerAccessCount }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.customerBackCount }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card shadow="hover" class="cardUser">
                        <div class="cardUserItem">
                            <div class="imgAll">
                                <img style="width: 30px;" src="@/assets/images/agent/u3.png" />
                            </div>
                            <div style="flex: 1.2">
                                <div style="font-size: 16px; font-weight: bold;">本月下单量</div>
                                <div class="cardUserItemshu">
                                    <div style="margin-left: 26px;">
                                        <div class="commonUser">瓦数</div>
                                        <div class="commonUser">户数</div>
                                    </div>
                                    <div style="margin-left: 20px;">
                                        <div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.orderPower
                                                }}</div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.orderHouse }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="6">
                    <el-card shadow="hover" class="cardUser">
                        <div class="cardUserItem">
                            <div class="imgAll">
                                <img style="width: 30px;" src="@/assets/images/agent/u4.png" />
                            </div>
                            <div style="flex: 1.2">
                                <div style="font-size: 16px; font-weight: bold;">电站数据</div>
                                <div class="cardUserItemshu">
                                    <div style="margin-left: 26px;">
                                        <div class="commonUser">电站装机总量</div>
                                        <div class="commonUser">电站总数</div>
                                    </div>
                                    <div style="margin-left: 26px">
                                        <div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.stationCapacity }}
                                            </div>
                                            <div class="commonUser" style="font-weight: bold; color: #000;">{{
                                                AgentManageIndexObj.stationCount }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 快捷入口 -->
        <div class="uickAccess">
            <el-card shadow="never" class="uickAccessCard">
                <div style="font-size: 16px; font-weight: bold;">快捷入口</div>
                <div style="display: flex;">
                    <el-card class="uickAccessItem" style="padding: 0px;" v-for="(item, index) in quickAccessList"
                        :key="index">
                        <div style="display: flex;" @click="toOthers(item.url)">
                            <img style="width: 40px; height: 40px;" :src="item.imge" alt="">
                            <div style="margin-top: 10px; margin-left: 20px; font-size: 14px; text-align: center;">{{
                                item.value }}</div>
                            <div style="margin-top: 10px; margin-left: 10px; color: red; font-size: 13px;">{{
                                item.number }}</div>
                        </div>
                    </el-card>
                </div>
            </el-card>
        </div>
        <div class="statisticalChart">
            <el-card class="salesStatement">
                <div>销售报表</div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="客户总数" name="first"></el-tab-pane>
                    <el-tab-pane label="并网完成数" name="second"></el-tab-pane>
                    <div id="chart" style="width: 100%; height: 340px;"></div>
                </el-tabs>
                <div class="dateYue">
                    <div style="display: flex;">
                        <el-link :underline="false" @click="changeDate(2)" class="dateitem"
                            :class="!dateFlag ? 'active' : ''">天</el-link>
                        <el-link :underline="false" @click="changeDate(1)" class="dateitem"
                            :class="dateFlag ? 'active' : ''">月</el-link>
                    </div>
                    <el-date-picker style="width: 350px;" @blur="changeTime" v-show="!dateFlag"
                        v-model="salesObj.starttime" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <el-date-picker style="width: 350px;" @blur="changeTime" v-show="dateFlag"
                        v-model="salesObj.Endtime" type="monthrange" size="mini" range-separator="至"
                        start-placeholder="开始月份" end-placeholder="结束月份">
                    </el-date-picker>

                </div>
            </el-card>
            <el-card class="customerStatistics">
                <div>电站监管</div>
                <div id="pieId" style="width: 100%; height: 340px;"></div>
            </el-card>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            activeName: "first",
            radio1: '',
            quickAccessList: [
                {
                    imge: require('@/assets/images/agent/u27.png'),
                    value: "客户管理",
                    url: '/userManage/dataCompletion1'
                },
                {
                    imge: require('@/assets/images/agent/u28.png'),
                    value: "代理商管理",
                    url: '/agentManagement/agentManagement1'
                },
                {
                    imge: require('@/assets/images/agent/u29.png'),
                    value: "审核",
                    url: '/prospecying'
                },
                {
                    imge: require('@/assets/images/agent/u30.png'),
                    value: "质量管理",
                    url: '/expoleManage1'
                },
                {
                    imge: require('@/assets/images/agent/u31.png'),
                    value: "财务",
                    url: '/settlement1'
                },
            ],

            dlcountObj: {}, // 代理商数量
            khcountObj: [], // 客户数量
            dscountObj: {}, // 电站数据

            salesObj: {
                starttime: '',
                Endtime: ''
            }, // 销售报表

            dateFlag: false,
            dayandmonth: 2,
            khzyObj: {}, // 客户统计
            getDzcountsmList: [],
            DzzfObj: {},
            flag: 0,
            ElectricDataList: [],
            orderMonthList: [],
            sdelectList: [],
            Selectshortcutcount: [],
            queryAgentInfoObj: [],
            AgentManageIndexObj: {},
            agentDifflist: [], // 头部数据
            CustomerNewNum: [],
        };
    },
    created() {
        this.getKhcount()
        this.getDlcount()
        this.getDscount()
        this.getkhzy()
        this.getDzcountsm()
        this.getDzcounmoy()
        this.getDzzf()
        // this.getAgentElectricData()
        this.orderMonth()
        this.getSelectList()
        this.getSelectshortcutcount()
        this.getQueryAgentIndexInfo()
        this.getSelectClient(1)
        this.getAgentManageIndex()
        this.getAgentDifflist()
    },
    mounted() {
        setTimeout(() => {
            this.drawChart()
            this.pieChart()
        }, 1000);
    },
    methods: {
        handleClick(tab, event) {
            this.flag = tab.index
            this.getSelectClient()
            // if (this.flag == 0) {
            //     this.getDzcountsm()
            // } else {
            //     this.getDzcounmoy()
            // }
        },
        changeDate(value) {
            if (value == 2) {
                this.dateFlag = false
            } else {
                this.dateFlag = true
            }
            console.log(this.dateFlag)
            this.dayandmonth = value
            this.getSelectClient()

            // if (this.flag == 0) {
            //     this.getDzcountsm()
            // } else {
            //     this.getDzcounmoy()
            // }
        },
        changeTime() {
            this.getSelectClient()
            // if (this.flag == 0) {
            //     this.getDzcountsm()
            // } else {
            //     this.getDzcounmoy()
            // }
        },
        // 跳转其他路由
        toOthers(url) {
            console.log(url)
            this.$router.push({ path: url });
        },
        async getQueryAgentIndexInfo() {
            let res = await this.$http.post("/houseCustomer/queryAgentlist", {
                agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
                    .agentCode,
            });
            if (res.data.code == 200) {
                this.queryAgentInfoObj = res.data.data || [];
            }
        },
        // 折线图方法
        drawChart() {
            let dataX = this.getDzcountsmList.map(res => {
                return res.month
            })
            let dataY = this.getDzcountsmList.map(res => {
                return res.count || res.price
            })
            // 创建一个新的Echarts实例
            const chart = echarts.init(document.getElementById('chart'));
            let option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: function (data) {
                        let html = ``;
                        html = `
                        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}</div>
                        `;
                        return html;
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,

                    data: dataX
                },
                yAxis: [
                    {
                        type: "value",
                    },

                ],
                series: [
                    {
                        name: "当前数",
                        type: "line",
                        stack: "Total",
                        // smooth: true,
                        data: dataY,
                        lineStyle: {
                            color: '#294ED4', // 设置折线的颜色为红色
                            width: 2,     // 设置折线的宽度为2像素
                            type: 'solid' // 设置折线的类型为实线，默认值
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'dashed' // 设置 Y 轴为虚线
                            }
                        }
                    },
                ],
            }
            option && chart.setOption(option);
        },
        // 饼状图方法
        pieChart() {
            // let allAmount = this.khcountObj[1][0].follow_up + this.khcountObj[0][1].life + this.khcountObj[0][0].life;
            // if (this.ElectricDataList == null) {
            //     return
            // }
            // let allAmount = this.ElectricDataList.allNum
            let chartDom = document.getElementById('pieId');
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    right: '0',
                    top: '5%',
                    // 设置图例为垂直排列
                    orient: 'vertical',
                    // 可以设置图例的位置
                    // left: 'right',
                    width: '60%'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        center: ['40%', '60%'], // 设置饼图中心位置为容器中心
                        label: ['新客户', '审核驳回', '审核通过', '报装完成', '并网预警', '客户总数'],
                        color: ['#FAC958', '#91CD76', '#D7D7D7', '#3A87EE', '#7180CE','blue', ],
                        labelLine: {
                            show: false
                        },
                        data: [
                            // { value: this.ElectricDataList.normalNum, name: '正常' },
                            // { value: this.ElectricDataList.abnormalNum, name: '异常' },
                            // { value: this.ElectricDataList.offlineNum, name: '离线' },

                            { value: this.AgentManageIndexObj.customerNewCount, name: '新增客户' },
                            { value: this.AgentManageIndexObj.customerBackCount, name: '审核驳回' },
                            { value: this.AgentManageIndexObj.customerNewCount, name: '审核通过' },
                            { value: this.AgentManageIndexObj.reportCount, name: '报装完成' },
                            { value: this.AgentManageIndexObj.bWCount, name: '并网预警' },
                            { value: this.AgentManageIndexObj.totalCountCustomer, name: '客户总数' },
                        ],
                        label: {
                            // formatter: function (params) {
                            //     // 如果数据项的值为0，不显示标签
                            //     return params.value !== 0 ? params.name : '';
                            // },
                            formatter: '{b}: {d}%'
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        // 在这里添加标题
                        // title: {
                        //     text: '总量',
                        //     left: 'center',
                        //     top: 'center',
                        //     textStyle: {
                        //         fontSize: 14
                        //     }
                        // },
                        // center: ['50%', '50%'], // 中心位置
                    },

                ],
                // graphic: {
                //     type: 'text',
                //     left: 'center',
                //     top: 'center',
                //     style: {
                //         text: '总量', // 要显示的文本
                //         textAlign: 'center',
                //         fill: '#000', // 文本颜色
                //         fontSize: 20
                //         // 其他样式...
                //     }
                // }
            };
            option && myChart.setOption(option);
        },
        showPopover(url) {
            this.$http.post(url, {}).then(res => {
                if (res.data.code == 200) {
                    this.CustomerNewNum = res.data.data
                }
            });
        },
        // 获取数据
        getSelectList() {
            this.$http.post("/AgentPlatform/SelectList", {}).then(res => {
                if (res.data.code == 200) {
                    this.sdelectList = res.data.data
                }
            });
        },
        // 获取头部数据
        getAgentDifflist() {
            this.$http.post("/houseCustomer/queryAgentDifflist", {}).then(res => {
                if (res.data.code == 200) {
                    this.agentDifflist = res.data.data
                }
            });
        },
        // 客户数量
        getSelectshortcutcount() {
            this.$http.post("/AgentPlatform/SelectshortcutCount", {}).then(res => {
                if (res.data.code == 200) {
                    this.Selectshortcutcount = res.data.data
                    this.quickAccessList = this.quickAccessList.map((item, index) => {
                        item.number = this.Selectshortcutcount[index]
                        return item
                    })
                } else {
                    this.Selectshortcutcount = []
                }
            });
        },

        // 客户数量
        getKhcount() {
            this.$http.post("/AgentPlatform/Khcount", {}).then(res => {
                if (res.data.code == 200) {
                    this.khcountObj = res.data.data
                }
            });
        },
        // 电站监管
        // getAgentElectricData() {
        //     this.$http.post("/houseCustomer/queryAgentElectricData", { agentCode: '1002' }).then(res => {
        //         if (res.data.code == 200) {
        //             this.ElectricDataList = res.data.data
        //             console.log(this.ElectricDataList, 'this.ElectricDataList')
        //         }
        //     });
        // },
        // 待结安装费
        getDzzf() {
            this.$http.post("/AgentPlatform/Dzzf", {}).then(res => {
                if (res.data.code == 200) {
                    this.DzzfObj = res.data.data
                }
            });
        },
        // 代理商数量
        getDlcount() {
            this.$http.post("/AgentPlatform/Dlcount", {}).then(res => {
                if (res.data.code == 200) {
                    this.dlcountObj = res.data.data[0]
                }
            });
        },
        // 本月下单量
        orderMonth() {
            this.$http.post("/AgentPlatform/OrderMonth", {}).then(res => {
                if (res.data.code == 200) {
                    this.orderMonthList = res.data.data[0]
                }
            });
        },
        // 电站数据
        getDscount() {
            this.$http.post("/AgentPlatform/Dscount", {}).then(res => {
                if (res.data.code == 200) {
                    this.dscountObj = res.data.data[0]
                }
            });
        },
        // 代理商
        getAgentManageIndex() {
            this.$http.post("/AgentPlatform/queryAgentManageIndex", {}).then(res => {
                if (res.data.code == 200) {
                    this.AgentManageIndexObj = res.data.data
                    console.log(this.AgentManageIndexObj,'kkkkkkkkkkkkkkkkkk')
                }
            });
        },
        // 客户统计
        getkhzy() {
            this.$http.post("/AgentPlatform/khzy", this.queryInfo).then(res => {
                if (res.data.code == 200) {
                    this.khzyObj = res.data.data
                }
            });
        },
        getSelectClient(ate = 0) {
            let data = {}
            if (ate == 1) {
                data.dayandmonth = 2,
                    data.state = 0
            } else {
                data.dayandmonth = this.dayandmonth,
                    data.state = this.flag
            }
            data.Starttime = this.salesObj.Endtime ? this.salesObj.Endtime[0] : ''
            data.Endtime = this.salesObj.Endtime ? this.salesObj.Endtime[1] : ''
            this.$http.post("/AgentPlatform/selectClient", data).then(res => {
                if (res.data.code == 200) {
                    this.getDzcountsmList = res.data.data
                    this.drawChart()
                }
            });
        },
        // 订单数量/月/日查询
        getDzcountsm() {
            let data = { dayandmonth: this.dayandmonth, }
            data.Starttime = this.salesObj.Endtime ? this.salesObj.Endtime[0] : ''
            data.Endtime = this.salesObj.Endtime ? this.salesObj.Endtime[1] : ''
            this.$http.post("/AgentPlatform/Dzcountsm", data).then(res => {
                if (res.data.code == 200) {
                    this.getDzcountsmList = res.data.data
                    this.drawChart()
                }
            });
        },
        // 订单总金额/月/日查询
        getDzcounmoy() {
            let data = { dayandmonth: this.dayandmonth, }
            data.Starttime = this.salesObj.Endtime ? this.salesObj.Endtime[0] : ''
            data.Endtime = this.salesObj.Endtime ? this.salesObj.Endtime[1] : ''
            this.$http.post("/AgentPlatform/Dzcounmoy", data).then(res => {
                if (res.data.code == 200) {
                    this.getDzcountsmList = res.data.data
                    this.drawChart()
                }
            });
        },

    },
};
</script>

<style lang="less" scoped>
.userManage {
    padding: 7px;

    background-color: #e7e7e7;
    .manage {

        .cardUser {
            height: 110px;
            border-radius: 10px;

            .cardUserItem {
                display: flex;

                .imgAll {
                    margin-right: 10px;
                }

                .cardUserItemshu {
                    display: flex;
                }

                .commonUser {
                    font-size: 12px;
                    margin-top: 7px;
                    color: #757575;
                }
            }
        }
    }

    /deep/ .el-card__body {
        padding: 10px;
    }

    .uickAccess {
        .uickAccessCard {
            margin-top: 7px;
            width: 100%;
            border-radius: 10px;

            .uickAccessItem {
                // height: 30px;
                margin: 10px 10px;
                width: 20%;
                background-color: #E8F3FC;
                border-radius: 10px;
            }
        }
    }

    .statisticalChart {
        margin-top: 7px;
        width: 100%;
        display: flex;
        height: calc(100vh - 450px);

        .salesStatement {
            position: relative;
            border-radius: 10px;
            flex: 1.2;

            .dateYue {
                display: flex;
                position: absolute;
                top: 40px;
                right: 50px;

                .dateitem {
                    // margin-top: 5px;
                    margin-right: 5px;
                    text-align: center;
                    width: 25px;
                    height: 30px;
                    line-height: 30px;
                }

                .active {
                    border-radius: 4px;
                    background-color: #E3E7FA;
                }
            }
        }

        /deep/ .el-tabs__nav-wrap::after {
            position: static !important;
            width: 0 !important;
            background-color: #fff !important;
        }

        .customerStatistics {
            margin-left: 10px;
            border-radius: 10px;
            flex: 0.8;
        }
    }

}

.orderManage-top {
    padding: 5px;

    .order-pic {
        background-color: #fff;
        text-align: center;
        box-sizing: border-box;
        padding: 5px 5px 10px 5px;
        box-sizing: border-box;
        font-size: 14px;

        .color {
            color: red;
            font-style: normal;
        }

        .pic-top {
            margin: 5px auto;
        }

        .el-icon-user {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            line-height: 30px;
            background-color: rgb(239, 246, 254);
            text-align: center;
            margin-right: 5px;
            color: #4c92f2;
            font-size: 18px;
        }
    }
}

/* 如果你还需要改变箭头的颜色，可以这样设置 */
/deep/ .custom-popover .popper__arrow::after {
    border-top-color: black !important;
}
/deep/ .el-popover .down-popover {
  background-color: rgb(0, 0, 0);
  margin-top: 20px;
  color: #13de61;
}
/deep/ .down-popover {
  background-color: rgb(0, 0, 0) !important;
  margin-top: 20px;
  color: #112233;
}
</style>
<style>
.down-popover {
  background-color: #5a5a5a !important;
  margin-top: 20px;
  color: #fff;
  opacity: 0.9;
}
.down-popover::after {
    border-top-color: #393B4A !important;
}
</style>
