import { render, staticRenderFns } from "./index2.vue?vue&type=template&id=6b0a7423&scoped=true"
import script from "./index2.vue?vue&type=script&lang=js"
export * from "./index2.vue?vue&type=script&lang=js"
import style0 from "./index2.vue?vue&type=style&index=0&id=6b0a7423&prod&lang=less&scoped=true"
import style1 from "./index2.vue?vue&type=style&index=1&id=6b0a7423&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b0a7423",
  null
  
)

component.options.__file = "index2.vue"
export default component.exports